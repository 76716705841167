import { atomFamily } from "recoil";
import { Errors } from "../types/Errors";

const key = "sgg_errors_family";

const errorsFamily = atomFamily<number | undefined, Errors>({
  key,
  default: undefined,
});

export default errorsFamily;

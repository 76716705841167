import {
  defaultLanguageStorageKey,
  getSupportedLanguage,
} from "./LanguageUtils";

export default {
  name: "SGG_DEFAULT_LANGUAGE_DETECTOR",

  lookup() {
    if (typeof navigator !== "undefined") {
      const userLanguage =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        // @ts-ignore
        navigator.userLanguage;
      if (!userLanguage || userLanguage === "en") {
        return "en-US";
      }
      return getSupportedLanguage(userLanguage);
    }
    return "en-US";
  },

  cacheUserLanguage(lng: string) {
    const defaultLanguage = localStorage.getItem(defaultLanguageStorageKey);
    if (defaultLanguage === null) {
      localStorage.setItem(defaultLanguageStorageKey, lng);
    }
  },
};

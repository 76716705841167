import { useEffect } from "react";
import {
  getAnalytics,
  Analytics,
  logEvent,
  setAnalyticsCollectionEnabled,
} from "firebase/analytics";
import {
  FirebaseApp,
  FirebaseOptions,
  getApp,
  initializeApp,
} from "firebase/app";
import useCookieConsent from "../utils/cookies/useCookieConsent";
import FirebaseEvent from "../utils/analytics/FirebaseEvents";

/*
  Taken from firebase console.
  https://console.firebase.google.com/project/globalgoals-c3900/settings/general/web:YWM3NTkwNzAtZTYxZi00Y2M3LWE5ZjQtYWUzZmYwOGNiYzRi
 */
const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "globalgoals-c3900.firebaseapp.com",
  databaseURL: "https://globalgoals-c3900.firebaseio.com",
  projectId: "globalgoals-c3900",
  storageBucket: "globalgoals-c3900.appspot.com",
  messagingSenderId: "486805344653",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const isDevelopmentMode =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

let app: FirebaseApp;
let analytics: Analytics;

const useAnalytics = () => {
  const [cookieState] = useCookieConsent();

  useEffect(() => {
    if (app) {
      return;
    }

    initializeApp(firebaseConfig);
    app = getApp();
    analytics = getAnalytics(app);
    setAnalyticsCollectionEnabled(analytics, false);
  }, []);

  useEffect(() => {
    const enabled = cookieState.performance && cookieState.isAcceptedByUser;
    setAnalyticsCollectionEnabled(analytics, enabled && !isDevelopmentMode);
  }, [cookieState]);

  const logCustomEvent = (
    event: FirebaseEvent,
    eventParams?: { [key: string]: any }
  ) => {
    if (isDevelopmentMode) {
      // eslint-disable-next-line no-console
      console.log(
        `Debug log event: ${event} params: ${JSON.stringify(eventParams)}`
      );
      return;
    }
    logEvent(analytics, event.toString(), eventParams);
  };

  const logBuildInEvent = (
    event: string,
    eventParams?: { [key: string]: any }
  ) => {
    if (isDevelopmentMode) {
      // eslint-disable-next-line no-console
      console.log(
        `Debug log event: ${event} params: ${JSON.stringify(eventParams)}`
      );
      return;
    }

    logEvent(analytics, event.toString(), eventParams);
  };

  return {
    logCustomEvent,
    logBuildInEvent,
  };
};

export default useAnalytics;

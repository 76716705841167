import React, { Suspense } from "react";
import {
  Routes,
  useNavigate,
  Route,
  useLocation,
  Navigate,
} from "react-router";
import LoadingPage from "./LoadingPage";
import SamsungAccountCallback from "./login/samsung/SamsungAccountCallback";
import useErrorHandler from "./hooks/useErrorHandler";

const PageLazy = (props: { children: React.ReactNode }) => {
  const { children } = props;
  return <Suspense fallback={<LoadingPage />}>{children}</Suspense>;
};

const IndiaNormal = React.lazy(() => import("./pages/IndiaNormal"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const Updates = React.lazy(() => import("./pages/Updates"));
const Goals = React.lazy(() => import("./pages/Goals"));
const Donate = React.lazy(() => import("./pages/Donate"));
const DonationFeed = React.lazy(() => import("./pages/DonationFeed"));
const Settings = React.lazy(() => import("./pages/Settings"));
const GoalDetails = React.lazy(() => import("./pages/GoalDetails"));
const Challenge = React.lazy(() => import("./pages/Challenge"));
const TopicLandingPage = React.lazy(() => import("./pages/TopicLandingPage"));
const Challenges = React.lazy(() => import("./pages/Challenges"));
const FAQ = React.lazy(() => import("./pages/faq/FAQ"));
const AdProvidersFAQ = React.lazy(() => import("./pages/faq/AdProvidersFAQ"));
const AdWall = React.lazy(() => import("./pages/AdWall"));
const WallpaperFeed = React.lazy(() => import("./pages/WallpaperFeed"));

function SGGRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const { render } = useErrorHandler();

  const query = new URLSearchParams(location.search);
  if (query.get("cc")) {
    query.delete("cc");
    navigate(query.toString(), { replace: true });
  }

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <PageLazy>
              <Updates />
            </PageLazy>
          }
        />
        <Route
          path="/Alia/*"
          element={
            <Navigate
              replace
              to="/topiclandingpage/00000000000000000000000000000000000000000000000000000aliafashion"
            />
          }
        />
        <Route
          path="/india/*"
          element={<Navigate replace to="/donateindia" />}
        />
        <Route
          path="/goals/exact/*"
          element={
            <PageLazy>
              <Goals />
            </PageLazy>
          }
        />
        <Route
          path="/goals/*"
          element={
            <PageLazy>
              <Goals />
            </PageLazy>
          }
        />
        <Route
          path="/donate/*"
          element={
            <PageLazy>
              <Donate />
            </PageLazy>
          }
        />
        <Route
          path="/donationfeed/:artId/*"
          element={
            <PageLazy>
              <DonationFeed />
            </PageLazy>
          }
        />
        <Route
          path="/adwall/*"
          element={
            <PageLazy>
              <AdWall />
            </PageLazy>
          }
        />
        <Route
          path="/settings/*"
          element={
            <PageLazy>
              <Settings />
            </PageLazy>
          }
        />
        <Route
          path="/goal/:goalNo/*"
          element={
            <PageLazy>
              <GoalDetails />
            </PageLazy>
          }
        />
        <Route
          path="/goal/exact/:goalNo/*"
          element={
            <PageLazy>
              <GoalDetails />
            </PageLazy>
          }
        />
        <Route path="/tax/*" element={<Navigate replace to="/donateindia" />} />
        <Route
          path="/top10/*"
          element={<Navigate replace to="/donateindia" />}
        />
        <Route
          path="/updates/*"
          element={
            <PageLazy>
              <Updates />
            </PageLazy>
          }
        />
        <Route
          path="/goals/exact/*"
          element={
            <PageLazy>
              <Goals />
            </PageLazy>
          }
        />
        <Route
          path="/goals/*"
          element={
            <PageLazy>
              <Goals />
            </PageLazy>
          }
        />
        <Route
          path="/donateindia/*"
          element={
            <PageLazy>
              <IndiaNormal />
            </PageLazy>
          }
        />
        <Route
          path="/challenges/*"
          element={
            <PageLazy>
              <Challenges />
            </PageLazy>
          }
        />
        <Route
          path="/wallpaper_feed/*"
          element={
            <PageLazy>
              <WallpaperFeed />
            </PageLazy>
          }
        />
        <Route
          path="/challenge/:challengeId/*"
          element={
            <PageLazy>
              <Challenge />
            </PageLazy>
          }
        />
        <Route
          path="/topiclandingpage/:pageId/*"
          element={
            <PageLazy>
              <TopicLandingPage />
            </PageLazy>
          }
        />
        <Route
          path="/faq/*"
          element={
            <PageLazy>
              <FAQ />
            </PageLazy>
          }
        />
        <Route
          path="/adprovidersfaq/*"
          element={
            <PageLazy>
              <AdProvidersFAQ />
            </PageLazy>
          }
        />
        <Route
          path="/login/sa/callback/*"
          element={<SamsungAccountCallback />}
        />
        <Route
          path="/404/*"
          element={
            <PageLazy>
              <NotFound />
            </PageLazy>
          }
        />
      </Routes>
      {render()}
    </>
  );
}

export default SGGRoutes;

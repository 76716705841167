import SamsungOne200 from "./SamsungOne-200_v1.1_20161104.ttf";
import SamsungOne400 from "./SamsungOne-400_v1.1_20161104.ttf";
import SamsungOne600 from "./SamsungOne-600_v1.1_20161104.ttf";
import SamsungOne800 from "./SamsungOne-800_v1.1_20161104.ttf";
import RobotCondensedBold from "./roboto_condensed_bold_file.ttf";

export const fontsVi = [
  new FontFace("SamsungOne", `url(${SamsungOne200})`, {
    weight: "200 300",
  }),
  new FontFace("SamsungOne", `url(${SamsungOne400})`, {
    weight: "400 500",
  }),
  new FontFace("SamsungOne", `url(${SamsungOne600})`, {
    weight: "600 700",
  }),
  new FontFace("SamsungOne", `url(${SamsungOne800})`, {
    weight: "800 900",
  }),
  new FontFace("SamsungSharpSans", `url(${SamsungOne400})`, {
    weight: "400",
  }),
  new FontFace("SamsungSharpSans", `url(${SamsungOne600})`, {
    weight: "500",
  }),
  new FontFace("SamsungSharpSans", `url(${SamsungOne800})`, {
    weight: "700",
  }),
  new FontFace("GiorgioSans", `url(${RobotCondensedBold})`),
];

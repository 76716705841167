import React from "react";
import styled from "@emotion/styled";
import { Center } from "@chakra-ui/react";
import GGLogo from "./assets/icons/GGLogo";

const Spinning = styled.div`
  animation: spin 5s linear infinite;
  width: 200px;
  height: 200px;
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
const LoadingPage = () => {
  return (
    <Center height="100%" background="#202020">
      <Spinning>
        <GGLogo width={200} height={200} />
      </Spinning>
    </Center>
  );
};

export default LoadingPage;

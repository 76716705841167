import { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  CardContainerParams,
  cardContainerParamsAtom,
} from "../atoms/cardContainerParams";

const useCardContainerParams = (): CardContainerParams => {
  return useRecoilState(cardContainerParamsAtom)[0];
};

export const CardContainerParamsProvider = (props: {
  children: JSX.Element;
}) => {
  const { children } = props;
  const [prevWidth, setPrevWidth] = useState<number>(window.innerWidth);
  const setCardContainerParams = useSetRecoilState(cardContainerParamsAtom);

  const calculateParams = () => {
    const minColWidth = isMobile ? 258 : 330;
    const itemGap = 16;
    const maxColWidth = 360;
    const windowWidth = isMobile ? window.innerWidth : window.innerWidth * 0.95;
    const cols = Math.min(Math.floor(windowWidth / (minColWidth + itemGap)), 3);
    let colSize = Math.floor((windowWidth - (cols - 1) * itemGap) / cols);

    if (colSize > maxColWidth && !isMobile) {
      colSize = Math.min(colSize, maxColWidth);
    }
    const widthPx = cols * (colSize + itemGap) - itemGap;
    const containerWidth = isMobile ? "100%" : `${widthPx}px`;
    document.documentElement.style.setProperty(
      "--card-container-width",
      containerWidth
    );
    return {
      columns: cols,
      widthCss: containerWidth,
      width: isMobile ? windowWidth : widthPx,
      columnSize: colSize,
    };
  };

  const handleResize = () => {
    if (prevWidth !== window.innerWidth) {
      setPrevWidth(window.innerWidth);
      setCardContainerParams(calculateParams());
    }
  };

  const debouncedCallback = useMemo(() => _.debounce(handleResize, 200), []);

  useEffect(() => {
    window.addEventListener("resize", debouncedCallback);
    setCardContainerParams(calculateParams());
    return () => {
      window.removeEventListener("resize", debouncedCallback);
      debouncedCallback.cancel();
    };
  }, []);

  return children;
};

export default useCardContainerParams;

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import SGGApp from "./SGGApp";
import "./i18n";
// import notificationManager from './notification/NotificationManager';

ReactDOM.render(
  <React.StrictMode>
    <SGGApp />
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorkerRegistration.register();
// notificationManager.requestPermission();

import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import getCountryAPI from "../api/getCountryAPI";
import { countryAtom } from "../atoms/country";
import { getLanguage } from "../utils/LanguageUtils";

const requestCountryFromServer = async () => {
  const lang = getLanguage();
  return getCountryAPI({ language: [lang] })
    .then((res) => {
      switch (+res.data.code) {
        case 200:
          return res.data.country;
        default:
          return "US";
      }
    })
    .catch(() => {
      return "US";
    });
};

const useCountry = (): string => {
  return useRecoilState(countryAtom)[0];
};

export const CountryProvider = (props: {
  children: JSX.Element;
  loading: JSX.Element;
}) => {
  const { children, loading } = props;
  const [countryFromServer, setCountryFromServer] = useState<string | null>(
    null
  );
  const setCountry = useSetRecoilState(countryAtom);
  const envCountry = process.env.REACT_APP_GG_COUNTRY;

  useEffect(() => {
    let active = true;

    const urlCountry = new URLSearchParams(window.location.search)
      .get("setcountry")
      ?.toUpperCase();

    if (urlCountry) {
      setCountryFromServer(urlCountry);
    } else {
      requestCountryFromServer().then((result) => {
        if (active) {
          setCountryFromServer(result);

          if (!localStorage.getItem("sgg_country")) {
            localStorage.setItem("sgg_country", result);
          }
        }
      });
    }

    return () => {
      active = false;
    };
  }, []);

  const providedCountry = envCountry || countryFromServer;

  useEffect(() => {
    setCountry(providedCountry || "US");
  }, [providedCountry]);

  if (!providedCountry) {
    return loading;
  }
  return children;
};

export default useCountry;

import i18n from "../../i18n";
import { fonts } from "./fonts";
import { fontsVi } from "./fontsVi";

const loadFonts = (fontArray: FontFace[]) => {
  return Promise.all(fontArray.map((font: FontFace) => font.load())).then(
    (loadedFonts: FontFace[]) => {
      loadedFonts.forEach((font: FontFace) => {
        document.fonts.add(font);
      });
    }
  );
};

const initialLoad: Promise<void> = loadFonts(fonts);

i18n.on("languageChanged", (lng: string) => {
  initialLoad.then(() => {
    document.fonts.clear();
    if (lng.toLowerCase() === "vi") {
      loadFonts(fontsVi);
    } else {
      loadFonts(fonts);
    }
  });
});

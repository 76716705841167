import { Theme } from "./Theme";
import { colors } from "./colors";

export const dark: Theme = {
  active: colors.blueMaster,
  blueText: colors.deepBlue,
  inactive: colors.deepBlue,
  primary: colors.blue,
  secondary: colors.darkGray,
  text: colors.white,
  textSecondary: colors.extraDarkMediumGray,
  surfaceBackground: colors.extraDarkGray,
  surfaceBackgroundSecondary: colors.superDarkGray,
  background: colors.black,
  borderSurface: colors.extraLightGray,
  greyRipple: colors.superDarkGray,
  blueRipple: colors.lightBlue,
  linkHover: colors.blue,
};

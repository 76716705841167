import avatar_black_blue from './avatar_black_blue.svg'
import avatar_black_orange from './avatar_black_orange.svg'
import avatar_white_blue from './avatar_white_blue.svg'
import avatar_white_orange from './avatar_white_orange.svg'

export const avatars = [
    avatar_black_blue,
    avatar_black_orange,
    avatar_white_blue,
    avatar_white_orange
];

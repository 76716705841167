import { atom } from "recoil";
import Cookies from "universal-cookie";
import { localStoragePersistenceEffect } from "../atomEffects/localStoragePersistenceEffect";

const key = "sgg_user_session";

export type UserSession = {
  token: string | null;
  tokenExpireTime: number;
};
const sessionCookies = new Cookies("session");
export const userSessionAtom = atom<UserSession>({
  key,
  default: {
    token: sessionCookies.get("sgg_token") || null,
    tokenExpireTime: sessionCookies.get("sgg_token_expire_time") || 0,
  },
  effects: [localStoragePersistenceEffect<UserSession>(key)],
});

import React from "react";
import { Flex, VStack } from "@chakra-ui/react";
import styled from "@emotion/styled";
import buildInfo from "../buildInfo";
import useDevMode from "../hooks/useDevMode";

const Badge = styled(Flex)`
  position: fixed;
  top: 0;
  right: 20px;
  background: #ffce69;
  color: black;
  font-family: monospace;
  padding: 2px;
`;

const BuildBadge = () => {
  const devMode = useDevMode();

  if (!devMode) {
    return null;
  }
  return (
    <Badge>
      <VStack>
        <span>
          Build time: {new Date(buildInfo.timestamp).toLocaleString("pl-pl")}
        </span>
        <span>Version: {buildInfo.version}</span>
      </VStack>
    </Badge>
  );
};

export default BuildBadge;

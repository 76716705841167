import { useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import GGPopup from "../layout/GGPopup";
import errorsFamily from "../atoms/errorsFamily";
import { Errors } from "../types/Errors";

const useError = (
  error: Errors,
  disclosure: ReturnType<typeof useDisclosure>
): {
  resetError: () => void;
} => {
  const [errorValue, setError] = useRecoilState(errorsFamily(error));

  useEffect(() => {
    if (errorValue) {
      disclosure.onOpen();
    }

    return () => {
      disclosure.onClose();
    };
  }, [errorValue]);

  const resetError = () => {
    setError(undefined);
    disclosure.onClose();
  };

  return { resetError };
};

const useErrorHandler = (): {
  render: () => React.ReactNode;
} => {
  const { t } = useTranslation();
  const genericErrorDisclosure = useDisclosure({ defaultIsOpen: false });
  const sessionExpiredDisclosure = useDisclosure({ defaultIsOpen: false });

  const { resetError: resetSessionError } = useError(
    Errors.SESSION_EXPIRED,
    sessionExpiredDisclosure
  );
  const { resetError: resetGenericError } = useError(
    Errors.GENERIC_ERROR,
    genericErrorDisclosure
  );

  const render = () => {
    return (
      <>
        <GGPopup
          title={t("action_error")}
          disclosure={genericErrorDisclosure}
          actions={[
            {
              actionText: t("ok"),
              onAction: resetGenericError,
            },
          ]}
        />
        <GGPopup
          disclosure={sessionExpiredDisclosure}
          title={t("session_expired")}
          actions={[
            {
              actionText: t("ok"),
              onAction: resetSessionError,
            },
          ]}
        />
      </>
    );
  };
  return { render };
};

export default useErrorHandler;

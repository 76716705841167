import axios from "axios";
import { getRecoil, resetRecoil, setRecoil } from "recoil-nexus";
import { getLanguage } from "../utils/LanguageUtils";
import { UserSession, userSessionAtom } from "../atoms/userSession";
import { googleUserAtom } from "../atoms/googleUser";
import errorsFamily from "../atoms/errorsFamily";
import { Errors } from "../types/Errors";
import { SamsungUser } from "../login/samsung/SamsungUser";
import { samsungUserAtom } from "../atoms/samsungUser";
import { avatars } from "../layout/images/ui_elements/avatars/avatars";
import { countryAtom } from "../atoms/country";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Referrer-Policy": "no-refferer",
    redirect: "follow",
    cache: "no-cache",
  },
});

// add token, language and header to each request
instance.interceptors.request.use((config) => {
  const newData = {
    token: config.data?.token || getRecoil(userSessionAtom).token,
    language: [getLanguage()],
    country: getRecoil(countryAtom),
    ...config.data,
  };
  return {
    ...config,
    data: newData,
  };
});

let refreshTokenPromise: Promise<UserSession> | undefined;
let refreshSamsungTokenPromise: Promise<SamsungUser> | undefined;

const refreshToken = (newToken: boolean = false, uid?: string) => {
  if (!refreshTokenPromise) {
    refreshTokenPromise = new Promise<UserSession>((resolve, reject) => {
      let data: { uid: string | null; token: string | null };
      if (uid) {
        data = { token: getRecoil(userSessionAtom).token, uid };
      } else {
        data = newToken
          ? { token: null, uid: null }
          : {
              token: getRecoil(userSessionAtom).token,
              uid: null,
            };
      }
      instance
        .post("validateSessionToken/", data)
        .then((response) => {
          const { token, token_expire_time: tokenExpireTime } = response.data;
          resolve({ token, tokenExpireTime });
        })
        .catch(reject)
        .finally(() => {
          refreshTokenPromise = undefined;
        });
    });
  }
  return refreshTokenPromise;
};

const refreshSamsungToken = () => {
  if (!refreshSamsungTokenPromise) {
    refreshSamsungTokenPromise = new Promise<SamsungUser>((resolve, reject) => {
      const samsungUser = getRecoil(samsungUserAtom);
      if (samsungUser) {
        instance
          .post("getSaToken/", {
            code: null,
            refreshToken: samsungUser.refreshToken,
            redirectUri: null,
          })
          .then((res) => {
            const { tokenData, debugErrorCode } = res.data;
            if (tokenData) {
              const newSamsungUser: SamsungUser = {
                id: samsungUser.id,
                givenName: tokenData.given_name,
                familyName: tokenData.family_name,
                email: tokenData.email,
                pictureUrl:
                  tokenData.picture ||
                  avatars[Math.floor(Math.random() * avatars.length)],
                token: tokenData.access_token,
                refreshToken: tokenData.refresh_token,
                refreshTokenExpireTime:
                  Date.now() + tokenData.refresh_token_expires_in,
                tokenExpireTime: Date.now() + tokenData.access_token_expires_in,
              };
              setRecoil(samsungUserAtom, newSamsungUser);
              resolve(newSamsungUser);
            } else if (debugErrorCode === "AUT_1803") {
              resetRecoil(samsungUserAtom);
              setRecoil(errorsFamily(Errors.SESSION_EXPIRED), Date.now());
              reject();
            } else {
              reject();
            }
          })
          .catch((res) => {
            const { debugErrorCode } = res;
            if (debugErrorCode === "AUT_1803") {
              resetRecoil(samsungUserAtom);
              setRecoil(errorsFamily(Errors.SESSION_EXPIRED), Date.now());
            }
            reject();
          })
          .finally(() => {
            refreshSamsungTokenPromise = undefined;
          });
      } else {
        reject();
      }
    });
  }
  return refreshSamsungTokenPromise;
};

instance.interceptors.response.use(async (response) => {
  if (
    response.data.code === 404 ||
    response.data.code === 426 ||
    response.data.code === 205
  ) {
    try {
      const refreshedTokenResponse = await refreshToken(
        response.data.code === 404,
        response.data.code === 205 ? response.data.uid : undefined
      );
      const { token } = refreshedTokenResponse;
      setRecoil(userSessionAtom, refreshedTokenResponse);
      const oldData = JSON.parse(response.config.data);
      const newConfigData = { ...oldData, token };
      const newConfig = { ...response.config, data: newConfigData };
      return await instance.request(newConfig);
    } catch (e) {
      return await Promise.reject(e);
    }
  } else if (response.data.code === 428) {
    // google token invalid
    resetRecoil(googleUserAtom);
    setRecoil(errorsFamily(Errors.SESSION_EXPIRED), Date.now());
    await Promise.reject();
  } else if (response.data.code === 401) {
    // samsung token expired
    try {
      const newSamsungUser = await refreshSamsungToken();
      const { token } = newSamsungUser;
      const oldData = JSON.parse(response.config.data);
      const newConfigData = { ...oldData, saToken: token };
      const newConfig = { ...response.config, data: newConfigData };
      return await instance.request(newConfig);
    } catch (e) {
      return await Promise.reject(e);
    }
  } else if (
    response.data.code === 406 ||
    response.data.code === 407 ||
    response.data.code === 408
  ) {
    setRecoil(errorsFamily(Errors.GENERIC_ERROR), Date.now());
    await Promise.reject();
  } else if (response.data.code !== 200) {
    const msg = {
      ...response.data,
      code: response.data.code,
      msg: response.data.message,
    };
    await Promise.reject(msg);
  }
  return response;
});

export default instance;

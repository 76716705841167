import { useEffect, useState } from "react";

const useLocalStorage = <T extends any>(
  key: string
): [T | undefined, (val: T | undefined) => void] => {
  const [value, setValue] = useState<T | undefined>(() => {
    const existingValue = window.localStorage.getItem(key);
    if (existingValue !== null) {
      return JSON.parse(existingValue);
    }
    return undefined;
  });

  useEffect(() => {
    if (value === undefined) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [value]);

  return [value, setValue];
};
export default useLocalStorage;

import api from "./api";
import BaseAPIResponse from "./baseApiResponse";

export type ResponseType = BaseAPIResponse & {
  tokenData: {
    access_token: string;
    token_type: string;
    access_token_expires_in: number;
    expires_in: number;
    refresh_token: string;
    refresh_token_expires_in: number;
    userId: string;
    id_token: string;
    given_name: string;
    family_name: string;
    picture: string | null;
    email: string;
  } | null;
  debugErrorCode: string | null;
};

const getSaTokenAPI = (data: {
  code: string | null;
  redirectUri: string | null;
  refreshToken: string | null;
}) => {
  return api.post<ResponseType>("/getSaToken/", data);
};

export default getSaTokenAPI;

import React from "react";

const GGLogo = (props: { width: number; height: number }) => {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 95 95"
    >
      <path
        d="M24.481,34.172C25.6461,32.1915 27.0607,30.378 28.6792,28.7718L14.914,13.6762C11.4503,16.9871 8.498,20.8233 6.1748,25.0532L24.481,34.172Z"
        fill="#3F7E44"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M58.2271,23.508C60.3113,24.464 62.2479,25.6836 63.9999,27.1246L77.802,12.0537C74.1679,8.9186 70.0434,6.3318 65.5692,4.4216L58.2271,23.508Z"
        fill="#DDA63A"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M89.8386,28.047L71.5429,37.1676C72.4181,39.2061 73.0384,41.3729 73.3758,43.6328L93.7223,41.7103C93.1336,36.8917 91.798,32.298 89.8386,28.047"
        fill="#C5192D"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M70.2313,34.5226L88.5251,25.4038C86.2546,21.1932 83.3726,17.3693 79.9774,14.0549L66.1718,29.1223C67.7429,30.7373 69.1118,32.5509 70.2313,34.5226"
        fill="#4C9F38"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M20.8457,47.5252C20.8457,47.1139 20.858,46.7027 20.8774,46.2933L0.5204,44.4709C0.4554,45.4814 0.4167,46.4971 0.4167,47.5252C0.4167,51.43 0.9035,55.224 1.8032,58.8477L21.4643,53.2084C21.0619,51.3755 20.8457,49.4758 20.8457,47.5252"
        fill="#3F7E44"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M67.6701,64.2575C66.2221,66.0183 64.5526,67.5876 62.7056,68.9285L73.4518,86.3296C77.4409,83.6268 80.989,80.3248 83.9835,76.5535L67.6701,64.2575Z"
        fill="#FCC30B"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M73.6607,47.5252C73.6607,49.4512 73.4516,51.3298 73.0544,53.1416L92.7102,58.7897C93.6117,55.1801 94.0897,51.4071 94.0897,47.5252C94.0897,46.5604 94.0563,45.6044 94.0019,44.6502L73.6414,46.5727C73.6519,46.8925 73.6607,47.2071 73.6607,47.5252"
        fill="#FF3A21"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M27.1656,64.6469L10.8962,77.0133C13.9224,80.7388 17.5073,83.9846 21.5193,86.6364L32.2672,69.2546C30.3745,67.9436 28.6594,66.3937 27.1656,64.6469"
        fill="#FD9D24"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M21.1752,43.3557C21.539,41.0554 22.2068,38.8587 23.1259,36.7973L4.8373,27.6855C2.8163,31.9857 1.4298,36.6409 0.8007,41.5298L21.1752,43.3557Z"
        fill="#0A97D9"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M70.9525,87.9038L60.2204,70.5238C58.2698,71.6256 56.1698,72.4902 53.9555,73.0701L57.7426,93.1723C62.4557,92.0915 66.9,90.292 70.9525,87.9038"
        fill="#A21942"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M72.2728,55.9887C71.561,58.0781 70.598,60.0516 69.4136,61.874L85.7322,74.1841C88.391,70.3725 90.484,66.1513 91.9198,61.6332L72.2728,55.9887Z"
        fill="#26BDE2"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M51.0615,73.6559C49.8191,73.8351 48.545,73.9335 47.2534,73.9335C46.213,73.9335 45.1868,73.8685 44.1781,73.7508L40.3945,93.8564C42.6351,94.1851 44.9214,94.359 47.2534,94.359C49.8367,94.359 52.3743,94.1464 54.8451,93.7422L51.0615,73.6559Z"
        fill="#FD6925"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M48.9424,21.1695C51.2093,21.3154 53.4007,21.7477 55.4761,22.4313L62.8183,3.3501C58.4513,1.8072 53.7891,0.8951 48.9424,0.7229L48.9424,21.1695Z"
        fill="#E5243B"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M41.269,73.2485C38.9809,72.716 36.8036,71.8831 34.7861,70.797L24.033,88.1894C28.1715,90.5565 32.6932,92.3121 37.4872,93.3314L41.269,73.2485Z"
        fill="#DD1367"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M39.3155,22.3371C41.4384,21.6675 43.6737,21.2581 45.9881,21.1509L45.9881,0.706C41.0728,0.8378 36.3509,1.7323 31.9241,3.2682L39.3155,22.3371Z"
        fill="#19486A"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M25.3758,62.2973C24.0789,60.3801 23.021,58.2818 22.26,56.0518L2.6148,61.691C4.1032,66.3761 6.3069,70.7413 9.1029,74.6619L25.3758,62.2973Z"
        fill="#BF8B2E"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
      <path
        d="M30.891,26.8114C32.6167,25.4442 34.5182,24.2932 36.5549,23.3864L29.1653,4.3263C24.7667,6.1733 20.7108,8.6757 17.1153,11.7054L30.891,26.8114Z"
        fill="#00558A"
        fillRule="evenodd"
        strokeWidth="1"
        stroke="#00000000"
      />
    </svg>
  );
};

export default GGLogo;

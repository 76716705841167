import i18n from "i18next";
import { languages } from "../locale/languages";

const rtlLanguages = ["ar", "fa", "iw", "ur"];

export const defaultLanguageStorageKey = "sgg_user_language";

export const DEFAULT_LANGUAGE = "en-US";

export const getSupportedLanguage = (language: string) => {
  // https://developer.android.com/reference/java/util/Locale#public-constructors_1
  // Note that Java uses several deprecated two-letter codes.
  // The Hebrew ("he") language code is rewritten as "iw",
  // Indonesian ("id") as "in", and Yiddish ("yi") as "ji".
  if (language && language.startsWith("he")) {
    return "iw";
  }

  if (language && language.startsWith("id")) {
    return "in";
  }

  if (!languages.includes(language)) {
    if (!language) return DEFAULT_LANGUAGE;

    const [languageFirst] = language.split("-");
    const possibleLanguages = [
      languageFirst,
      `${languageFirst}-${languageFirst.toUpperCase()}`,
    ];
    for (let i = 0; i < possibleLanguages.length; i += 1) {
      if (languages.includes(possibleLanguages[i])) {
        return possibleLanguages[i];
      }
    }
    // look for language starting with 1st block of passed language
    for (let i = 0; i < languages.length; i += 1) {
      if (possibleLanguages[i].startsWith(`${languageFirst}-`)) {
        return possibleLanguages[i];
      }
    }
  } else {
    return language;
  }
  return DEFAULT_LANGUAGE;
};

export const getLanguage = () => {
  return getSupportedLanguage(i18n.language);
};

export const getDefaultLanguage = () => {
  return localStorage.getItem(defaultLanguageStorageKey) || DEFAULT_LANGUAGE;
};

export const getLanguageTag = (languageCode: string) => {
  if (!Intl.DisplayNames) {
    const languageTags: { [s: string]: string } = {
      ar: "العربية",
      as: "অসমীয়া",
      az: "azərbaycan dili",
      be: "Беларуская",
      bg: "български език",
      bn: "বাংলা",
      bo: "བོད་ཡིག",
      bs: "bosanski jezik",
      ca: "Català",
      cs: "česky, čeština",
      da: "dansk",
      de: "Deutsch",
      el: "Ελληνικά",
      en: "English",
      es: "español, castellano",
      et: "eesti, eesti keel",
      eu: "euskara, euskera",
      fa: "فارسی",
      fi: "suomi, suomen kieli",
      fr: "français, langue française",
      ga: "Gaeilge",
      gl: "Galego",
      gu: "ગુજરાતી",
      iw: "עברית",
      in: "Bahasa Indonesia",
      hi: "हिन्दी, हिंदी",
      hr: "hrvatski",
      hu: "Magyar",
      hy: "Հայերեն",
      is: "Íslenska",
      it: "Italiano",
      ja: "日本語 (にほんご／にっぽんご)",
      ka: "ქართული",
      kk: "Қазақ тілі",
      km: "ភាសាខ្មែរ",
      kn: "ಕನ್ನಡ",
      ko: "한국어 (韓國語), 조선말 (朝鮮語)",
      ky: "кыргыз тили",
      lo: "ພາສາລາວ",
      lt: "lietuvių kalba",
      lv: "latviešu valoda",
      mk: "македонски јазик",
      ml: "മലയാളം",
      mn: "монгол",
      mr: "मराठी",
      ms: "bahasa Melayu, بهاس ملايو‎",
      my: "ဗမာစာ",
      nb: "Norsk bokmål",
      ne: "नेपाली",
      nl: "Nederlands, Vlaams",
      or: "ଓଡ଼ିଆ",
      pa: "ਪੰਜਾਬੀ, پنجابی‎",
      pl: "polski",
      pt: "Português",
      ro: "română",
      ru: "русский язык",
      si: "සිංහල",
      sk: "slovenčina",
      sl: "slovenščina",
      sq: "Shqip",
      sr: "српски језик",
      sv: "svenska",
      ta: "தமிழ்",
      te: "తెలుగు",
      tg: "тоҷикӣ, toğikī, تاجیکی‎",
      th: "ไทย",
      tk: "Türkmen, Түркмен",
      tl: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
      tr: "Türkçe",
      ug: "Uyƣurqə, ئۇيغۇرچە‎",
      uk: "українська",
      ur: "اردو",
      uz: "zbek, Ўзбек, أۇزبېك‎",
      vi: "Tiếng Việt",
      zh: "中文 (Zhōngwén), 汉语, 漢語",
    };
    console.log("Intl.DisplayNames not supported.");
    const codePrefix = languageCode.substring(0, 2);
    return languageTags[codePrefix];
  }
  const names = new Intl.DisplayNames([languageCode], { type: "language" });
  return names.of(languageCode);
};

export const isRtlLanguage = (languageCode: string) => {
  return rtlLanguages.some((rtl) => rtl.startsWith(languageCode));
};

import i18n from "../../i18n";

const saDomain = "https://account.samsung.com/accounts/v1/";
const saPathAuthorize = "/signInGate";
const saPathSignOut = "/signOutGate";
export const saRedirectUrl = `${window.location.protocol}//${window.location.host}/login/sa/callback`;

const SamsungAccountResponseType = {
  CODE: "code",
  ID_TOKEN: "id_token",
  CODE_ID_TOKEN: "code id_token",
};

const SamsungAccountScope = {
  ALL: "openid email profile",
  OPEN_ID: "openid",
  OPEN_ID_OFFLINE: "openid offline.access",
  EMAIL: "email",
  PROFILE: "profile",
};

export const generateStateId = () => {
  let stateId = sessionStorage.getItem("sa_state_id");
  if (stateId) return stateId;

  stateId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  sessionStorage.setItem("sa_state_id", stateId);
  return stateId;
};

export const saLoginUrl = (
  state: string,
  response_type: string = SamsungAccountResponseType.CODE,
  scope: string = SamsungAccountScope.OPEN_ID_OFFLINE
) => {
  const queryParams = [
    `response_type=${response_type}`,
    `redirect_uri=${saRedirectUrl}`,
    `scope=${scope}`,
    `client_id=${process.env.REACT_APP_SAMSUNG_ACCOUNT_CLIENT_ID}`,
    `state=${state}`,
    `locale=${i18n.language}`,
  ].join("&");

  return `${saDomain}${process.env.REACT_APP_SAMSUNG_ACCOUNT_APP_ALIAS}${saPathAuthorize}?${queryParams}`;
};

export const saSignOutUrl = (state: string) => {
  const queryParams = [
    `signOutURL=${saRedirectUrl}`,
    `client_id=${process.env.REACT_APP_SAMSUNG_ACCOUNT_CLIENT_ID}`,
    `state=${state}`,
  ].join("&");

  return `${saDomain}${process.env.REACT_APP_SAMSUNG_ACCOUNT_APP_ALIAS}${saPathSignOut}?${queryParams}`;
};

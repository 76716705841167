import { useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  generateStateId,
  saLoginUrl,
  saSignOutUrl,
  saRedirectUrl,
} from "./samsungAccoutUtils";
import getSaTokenAPI from "../../api/getSaTokenApi";
import { SamsungUser } from "./SamsungUser";
import { samsungUserAtom } from "../../atoms/samsungUser";
import { avatars } from "../../layout/images/ui_elements/avatars/avatars";

const redirectUrlKey = "sa_redirect_uri";

const useSamsungLogin = (): {
  samsungUser: SamsungUser | undefined;
  login: () => void;
  logout: () => void;
} => {
  const expectedStateId = sessionStorage.getItem("sa_state_id");
  const [samsungUser, setSamsungUser] = useRecoilState(samsungUserAtom);

  const login = () => {
    localStorage.setItem(redirectUrlKey, window.location.href);
    window.location.href = saLoginUrl(generateStateId());
  };

  const logout = () => {
    localStorage.setItem(redirectUrlKey, window.location.href);
    window.location.href = saSignOutUrl(generateStateId());
  };

  useEffect(() => {
    const { code, state, result } = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );
    if (code && state && state === expectedStateId) {
      getSaTokenAPI({
        code,
        refreshToken: null,
        redirectUri: saRedirectUrl,
      })
        .then((res) => {
          const { tokenData } = res.data;
          if (tokenData) {
            setSamsungUser({
              id: tokenData.userId,
              givenName: tokenData.given_name,
              familyName: tokenData.family_name,
              email: tokenData.email,
              pictureUrl:
                tokenData.picture ||
                avatars[Math.floor(Math.random() * avatars.length)],
              token: tokenData.access_token,
              refreshToken: tokenData.refresh_token,
              refreshTokenExpireTime:
                Date.now() + tokenData.refresh_token_expires_in,
              tokenExpireTime: Date.now() + tokenData.access_token_expires_in,
            });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          window.location.href = localStorage.getItem(redirectUrlKey)!;
        });
    } else if (state && result && state === expectedStateId) {
      setSamsungUser(undefined);
      window.location.href = localStorage.getItem(redirectUrlKey)!;
    }
  }, [window.location.search]);

  return { samsungUser, login, logout };
};
export default useSamsungLogin;

import { DefaultValue } from "recoil";

export const localStoragePersistenceEffect =
  <T>(key: string) =>
  (params: {
    setSelf: (
      param:
        | T
        | DefaultValue
        | Promise<T | DefaultValue>
        | ((param: T | DefaultValue) => T | DefaultValue)
    ) => void;
    onSet: (
      param: (newValue: T, oldValue: T | DefaultValue, isReset: boolean) => void
    ) => void;
  }) => {
    const { setSelf, onSet } = params;
    const nullReviver = (reviverKey: string, value: any) => {
      if (!value) return null;
      return value;
    };
    const nullReplacer = (replacerKey: string, value: any) => {
      if (!value) {
        return null;
      }
      return value;
    };
    const savedValue = localStorage.getItem(key);
    if (savedValue) {
      setSelf(JSON.parse(savedValue, nullReviver));
    }

    onSet((newValue, _: DefaultValue | T, isReset: boolean) => {
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue, nullReplacer));
      }
    });
  };

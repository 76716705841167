import React from "react";
import { css, Global } from "@emotion/react";

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        #root {
          height: 100%;
        }

        html,
        body {
          height: 100%;
          margin: 0;
        }

        html {
          overflow: hidden;
        }

        body {
          font-family: SamsungOne, sans-serif;
          font-weight: normal;
          font-size: 13px;
        }

        h1 {
          font-family: SamsungSharpSans, sans-serif;
          font-weight: bold;
          font-size: 1.4rem;
        }

        h2 {
          font-size: 1rem;
          font-weight: bold;
          color: white;
        }

        h3 {
          font-family: SamsungSharpSans, sans-serif;
          font-weight: 500;
          font-size: 1rem;
          margin: 0.5rem 0rem;
        }
      `}
    />
  );
};

export default GlobalStyles;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { translations } from "./locale/languages";
import default_lang from "./locale/en-US.json";
import fallback_en from "./locale/en.json";
import DefaultLanguageDetector from "./utils/DefaultLanguageDetector";
import { DEFAULT_LANGUAGE } from "./utils/LanguageUtils";

// this shouldn't be used as in app language, only for translations as a fallback
const FALLBACK_TRANSLATION_LANGUAGE = "en";

const resources = {
  [DEFAULT_LANGUAGE]: { translation: default_lang },
  en: { translation: fallback_en },
};

export const changeLanguage = (newLanguage: string) => {
  if (newLanguage !== DEFAULT_LANGUAGE) {
    import(`./locale/${translations[newLanguage]}`).then((data) => {
      i18n.addResourceBundle(newLanguage, "translation", data);

      i18n.changeLanguage(newLanguage, (err) => {
        if (!err) {
          localStorage.setItem("language", newLanguage);
        }
      });
    });
  } else {
    i18n.changeLanguage(newLanguage, (err) => {
      if (!err) {
        localStorage.setItem("language", newLanguage);
      }
    });
  }
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(DefaultLanguageDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["SGG_DEFAULT_LANGUAGE_DETECTOR"],
      caches: ["SGG_DEFAULT_LANGUAGE_DETECTOR"],
    },
    resources,
    fallbackLng: [DEFAULT_LANGUAGE, FALLBACK_TRANSLATION_LANGUAGE],
    react: {
      useSuspense: true,
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    const storageLanguage = localStorage.getItem("language");
    if (storageLanguage && i18n.language !== storageLanguage) {
      changeLanguage(storageLanguage);
    } else if (i18n.language !== i18n.resolvedLanguage) {
      changeLanguage(i18n.language);
    }
  });

export default i18n;

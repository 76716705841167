import { useEffect } from "react";
import useLocalStorage from "./useLocalStorage";

const getDevURLState = () => {
  const query = new URLSearchParams(window.location.search);
  if (query.get("devMode")?.toLowerCase() === "true") {
    return true;
  }
  if (query.get("devMode")?.toLowerCase() === "false") {
    return false;
  }
  return null;
};

const useDevMode = () => {
  const [devModeLS, setDevModeLS] = useLocalStorage<boolean>("sgg_dev_mode");

  useEffect(() => {
    const urlDevMode = getDevURLState();
    if (urlDevMode === true) {
      setDevModeLS(true);
    }
    if (urlDevMode === false) {
      setDevModeLS(false);
    }
  }, []);

  return devModeLS || false;
};

export default useDevMode;

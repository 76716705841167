export const translations: { [s: string]: string } = {
  ar: "ar.json",
  as: "as.json",
  "az-AZ": "az-AZ.json",
  "be-BY": "be-BY.json",
  bg: "bg.json",
  "bn-BD": "bn-BD.json",
  "bn-IN": "bn-IN.json",
  bo: "bo.json",
  bs: "bs.json",
  ca: "ca.json",
  cs: "cs.json",
  da: "da.json",
  de: "de.json",
  el: "el.json",
  en: "en.json",
  "en-CA": "en-CA.json",
  "en-DI": "en-DI.json",
  "en-GB": "en-GB.json",
  "en-PH": "en-PH.json",
  "en-US": "en-US.json",
  "en-ZG": "en-ZG.json",
  "es-ES": "es-ES.json",
  "es-US": "es-US.json",
  "et-EE": "et-EE.json",
  "eu-ES": "eu-ES.json",
  fa: "fa.json",
  fi: "fi.json",
  fr: "fr.json",
  "fr-CA": "fr-CA.json",
  ga: "ga.json",
  "gl-ES": "gl-ES.json",
  "gu-IN": "gu-IN.json",
  hi: "hi.json",
  hr: "hr.json",
  hu: "hu.json",
  "hy-AM": "hy-AM.json",
  in: "in.json",
  "is-IS": "is-IS.json",
  it: "it.json",
  iw: "iw.json",
  ja: "ja.json",
  "ka-GE": "ka-GE.json",
  "kk-KZ": "kk-KZ.json",
  "km-KH": "km-KH.json",
  "kn-IN": "kn-IN.json",
  ko: "ko.json",
  "ky-KG": "ky-KG.json",
  "lo-LA": "lo-LA.json",
  lt: "lt.json",
  lv: "lv.json",
  "mk-MK": "mk-MK.json",
  "ml-IN": "ml-IN.json",
  "mn-MN": "mn-MN.json",
  "mr-IN": "mr-IN.json",
  "ms-MY": "ms-MY.json",
  "my-MM": "my-MM.json",
  "my-ZG": "my-ZG.json",
  nb: "nb.json",
  ne: "ne.json",
  "ne-NP": "ne-NP.json",
  nl: "nl.json",
  or: "or.json",
  "pa-IN": "pa-IN.json",
  pl: "pl.json",
  "pl-SP": "pl-SP.json",
  "pt-BR": "pt-BR.json",
  "pt-PT": "pt-PT.json",
  ro: "ro.json",
  ru: "ru.json",
  si: "si.json",
  "si-LK": "si-LK.json",
  sk: "sk.json",
  sl: "sl.json",
  "sq-AL": "sq-AL.json",
  sr: "sr.json",
  sv: "sv.json",
  "ta-IN": "ta-IN.json",
  "te-IN": "te-IN.json",
  tg: "tg.json",
  th: "th.json",
  tk: "tk.json",
  tl: "tl.json",
  tr: "tr.json",
  "ug-CN": "ug-CN.json",
  uk: "uk.json",
  "ur-PK": "ur-PK.json",
  "uz-UZ": "uz-UZ.json",
  vi: "vi.json",
  "zh-CN": "zh-CN.json",
  "zh-HK": "zh-HK.json",
  "zh-TW": "zh-TW.json",
};

export const languages = [
  "ar",
  "as",
  "az-AZ",
  "be-BY",
  "bg",
  "bn-BD",
  "bn-IN",
  "bo",
  "bs",
  "ca",
  "cs",
  "da",
  "de",
  "el",
  "en",
  "en-CA",
  "en-DI",
  "en-GB",
  "en-PH",
  "en-US",
  "en-ZG",
  "es-ES",
  "es-US",
  "et-EE",
  "eu-ES",
  "fa",
  "fi",
  "fr",
  "fr-CA",
  "ga",
  "gl-ES",
  "gu-IN",
  "hi",
  "hr",
  "hu",
  "hy-AM",
  "in",
  "is-IS",
  "it",
  "iw",
  "ja",
  "ka-GE",
  "kk-KZ",
  "km-KH",
  "kn-IN",
  "ko",
  "ky-KG",
  "lo-LA",
  "lt",
  "lv",
  "mk-MK",
  "ml-IN",
  "mn-MN",
  "mr-IN",
  "ms-MY",
  "my-MM",
  "my-ZG",
  "nb",
  "ne",
  "ne-NP",
  "nl",
  "or",
  "pa-IN",
  "pl",
  "pl-SP",
  "pt-BR",
  "pt-PT",
  "ro",
  "ru",
  "si",
  "si-LK",
  "sk",
  "sl",
  "sq-AL",
  "sr",
  "sv",
  "ta-IN",
  "te-IN",
  "tg",
  "th",
  "tk",
  "tl",
  "tr",
  "ug-CN",
  "uk",
  "ur-PK",
  "uz-UZ",
  "vi",
  "zh-CN",
  "zh-HK",
  "zh-TW",
];

import { atom } from "recoil";
import { GoogleUser } from "../types/Google";
import { localStoragePersistenceEffect } from "../atomEffects/localStoragePersistenceEffect";

const key = "sgg_google_user";

export const googleUserAtom = atom<GoogleUser | undefined>({
  key,
  default: undefined,
  effects: [localStoragePersistenceEffect(key)],
});

export const colors = {
  black: "#080808",
  blue: "#0865c3",
  blueMaster: "#0074D4",
  deepBlue: "#0a78fc",
  darkBlue: "#062E52",
  superDarkGray: "#474747",
  darkGray: "#737373",
  extraDarkGray: "#252525",
  extraLightGray: "#F2F2F2",
  green: "#15B76C",
  extraLightBlue: "#AAD0F5",
  lightBlack: "#212121",
  lightBlue: "#4297FF",
  lightGray: "#CCCCCC",
  mediumGray: "#c4c4c4",
  darkMediumGray: "#b6b6b6",
  extraDarkMediumGray: "#979797",
  orange: "#c65306",
  purple: "#6446e6",
  red: "#f01346",
  white: "#fafafa",
  yellow: "#ff9e01",
  goal18: "#f1f1f1",
};

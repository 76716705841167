import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import "./layout/fonts/SamsungOne-200_v1.1_20161104.ttf";
import "./layout/fonts/SamsungOne-400_v1.1_20161104.ttf";
import "./layout/fonts/SamsungOne-600_v1.1_20161104.ttf";
import "./layout/fonts/SamsungOne-800_v1.1_20161104.ttf";
import "./layout/fonts/samsungsharpsans.woff";
import "./layout/fonts/samsungsharpsans-medium.woff";
import "./layout/fonts/samsungsharpsans-bold.woff";
import "./layout/fonts/giorgiosansbold.ttf";
import "./layout/fonts/roboto_condensed_bold_file.ttf";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import SGGRoutes from "./SGGRoutes";
import AnalyticsProvider from "./utils/analytics/AnalyticsProvider";
import { CountryProvider } from "./hooks/useCountry";
import LoadingPage from "./LoadingPage";
import BuildBadge from "./widgets/BuildBadge";
import useSetLanguage from "./hooks/useSetLanguage";
import "./layout/fonts/fontResolver";
import { CardContainerParamsProvider } from "./hooks/useCardContainerParams";
import SGGChakraProvider from "./style/chakra/SGGChakraProvider";
import StyledThemeProvider from "./style/styled/StyledThemeProvider";
import GlobalStyles from "./style/styled/GlobalStyles";

function SGGApp() {
  useSetLanguage();

  const MILLISECONDS_IN_HOUR = 1000 * 60 * 60;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: MILLISECONDS_IN_HOUR,
      },
    },
  });
  return (
    <RecoilRoot>
      <RecoilNexus />
      <QueryClientProvider client={queryClient}>
        <Router>
          <AnalyticsProvider>
            <StyledThemeProvider>
              <SGGChakraProvider>
                <>
                  <CountryProvider loading={<LoadingPage />}>
                    <CardContainerParamsProvider>
                      <>
                        <SGGRoutes />
                        <GlobalStyles />
                        <ReactQueryDevtools initialIsOpen={false} />
                      </>
                    </CardContainerParamsProvider>
                  </CountryProvider>
                  <BuildBadge />
                </>
              </SGGChakraProvider>
            </StyledThemeProvider>
          </AnalyticsProvider>
        </Router>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default SGGApp;

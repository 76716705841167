import { mode } from "@chakra-ui/theme-tools";

export const colors = {
  background: "#202020",
};

export const globalStyles = (props: any) => {
  return {
    body: {
      fontFamily: "SamsungOne, sans-serif",
      fontWeight: "normal",
      fontSize: "13px",
      color: mode("gray.800", "whiteAlpha.900")(props),
      transitionProperty: "background-color",
      transitionDuration: "normal",
      lineHeight: "base",
      bg: mode("#202020", "black")(props),
    },
  };
};

export const config = {
  initialColorMode: "light",
  useSystemColorMode: true,
};

import React from "react";
import AnalyticsContext from "./AnalyticsContext";
import useAnalytics from "../../hooks/useAnalytics";

const AnalyticsProvider = ({ children }: { children: any }) => {
  const analytics = useAnalytics();
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
export default AnalyticsProvider;

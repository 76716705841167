import React, { useEffect, useState } from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import modal from "./theme/modal";
import button from "./theme/button";
import { colors, config } from "./theme/theme";
import { isRtlLanguage } from "../../utils/LanguageUtils";

const SGGChakraProvider = (props: { children: JSX.Element }) => {
  const { children } = props;
  const { i18n } = useTranslation();

  const sggTheme = () =>
    extendTheme({
      colors,
      config,
      components: {
        Modal: modal,
        Button: button,
      },
      direction: isRtlLanguage(i18n.language) ? "rtl" : "ltr",
    });

  const [theme, setTheme] = useState<Record<string, any>>(sggTheme());

  useEffect(() => {
    setTheme(sggTheme());
    document
      .getElementsByTagName("html")[0]
      .setAttribute("dir", isRtlLanguage(i18n.language) ? "rtl" : "ltr");
  }, [i18n.language]);

  return (
    <ChakraProvider theme={theme} resetCSS>
      {children}
    </ChakraProvider>
  );
};

export default SGGChakraProvider;

import { useEffect, useState } from "react";

export enum Theme {
  Dark,
  Light,
}

const getThemeFromBrowser = () => {
  return window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
    ? Theme.Dark
    : Theme.Light;
};

const useTheme = (): Theme => {
  const [theme, setTheme] = useState<Theme>(getThemeFromBrowser());

  useEffect(() => {
    if (window.matchMedia) {
      const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      if (darkMediaQuery.addEventListener) {
        darkMediaQuery.addEventListener("change", () => {
          setTheme(getThemeFromBrowser);
        });
      } else {
        darkMediaQuery.addListener(() => {
          setTheme(getThemeFromBrowser);
        });
      }
    }
  }, []);

  return theme;
};

export default useTheme;

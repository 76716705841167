import { useEffect } from "react";
import { changeLanguage } from "../i18n";
import { languages } from "../locale/languages";

const useSetLanguage = () => {
  useEffect(() => {
    const urlLanguage = new URLSearchParams(window.location.search)
      .get("lang")
      ?.toLowerCase();
    if (urlLanguage) {
      if (!languages.includes(urlLanguage)) {
        return;
      }
      changeLanguage(urlLanguage);
    }
  }, []);
};

export default useSetLanguage;

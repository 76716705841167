import { Theme } from "./Theme";
import { colors } from "./colors";

export const light: Theme = {
  active: colors.lightBlue,
  blueText: colors.darkBlue,
  inactive: colors.extraLightBlue,
  primary: colors.blue,
  secondary: colors.black,
  text: colors.lightBlack,
  textSecondary: colors.darkGray,
  surfaceBackground: colors.white,
  surfaceBackgroundSecondary: colors.mediumGray,
  background: colors.lightBlack,
  borderSurface: colors.lightGray,
  greyRipple: colors.lightGray,
  blueRipple: colors.blue,
  linkHover: colors.deepBlue,
};

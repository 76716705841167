import { atom } from "recoil";
import { SamsungUser } from "../login/samsung/SamsungUser";
import { localStoragePersistenceEffect } from "../atomEffects/localStoragePersistenceEffect";

const key = "sgg_samsung_user";

export const samsungUserAtom = atom<SamsungUser | undefined>({
  key,
  default: undefined,
  effects: [localStoragePersistenceEffect(key)],
});

import { atom } from "recoil";

export type CardContainerParams = {
  columns: number;
  widthCss: string;
  width: number;
  columnSize: number;
};

export const cardContainerParamsAtom = atom<CardContainerParams>({
  key: "sgg_card_container_params",
  default: {
    columns: 0,
    widthCss: "0px",
    width: 0,
    columnSize: 0,
  },
});

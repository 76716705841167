import styled from "@emotion/styled";
import { Button } from "@chakra-ui/react";
import { colors } from "../style/styled/theme/colors";

export const ButtonRippled = styled(Button)`
  background-position: center;
  transition: background 0.8s;

  @media (any-hover: hover) {
    &:hover {
      background: ${(p) => p.theme.greyRipple}
        radial-gradient(circle, transparent 1%, ${(p) => p.theme.greyRipple} 1%)
        center/15000%;
    }
  }

  @media (any-hover: none) {
    background: ${(p) => p.theme.surfaceBackground}
      radial-gradient(
        circle,
        transparent 1%,
        ${(p) => p.theme.surfaceBackground} 1%
      )
      center/15000%;
  }

  &:active {
    background-color: ${colors.darkMediumGray};
    background-size: 100%;
    transition: background 0s;
  }
`;

export const ImageRippled = styled.img`
  transition: filter 0.8s;

  @media (any-hover: hover) {
    &:hover {
      filter: brightness(0.7);
    }
  }

  &:active {
    filter: brightness(0.4);
    transition: filter 0s;
  }
`;

export const BlueButtonRippled = styled(Button)`
  background-color: ${colors.deepBlue};
  background-position: center;
  transition: background 0.8s;

  @media (any-hover: hover) {
    &:hover {
      background: ${(p) => p.theme.blueRipple}
        radial-gradient(circle, transparent 1%, ${(p) => p.theme.blueRipple} 1%)
        center/15000%;
    }
  }

  @media (any-hover: none) {
    background: ${colors.deepBlue}
      radial-gradient(circle, transparent 1%, ${colors.deepBlue} 1%)
      center/15000%;
  }

  &:active {
    background-color: ${colors.darkMediumGray};
    background-size: 100%;
    transition: background 0s;
  }
`;

export const TransparentButtonRippled = styled(Button)`
  background-position: center;
  transition: background 0.8s;
  background-color: transparent;
  color: ${(p) => p.theme.primary};

  @media (any-hover: hover) {
    &:hover {
      background: ${`${colors.lightGray}64`}
        radial-gradient(circle, transparent 1%, ${colors.lightGray} 1%)
        center/15000%;
    }
  }

  @media (any-hover: none) {
    background: transparent
      radial-gradient(circle, transparent 1%, ${colors.lightGray} 1%)
      center/15000%;
  }

  &:active {
    background-color: ${colors.lightGray};
    background-size: 100%;
    transition: background 0s;
  }
`;

export const ColoredButtonRippled = styled(Button)<{ color: string }>`
  background-position: center;
  transition: background 0.8s;
  background-color: ${(p) => p.color};

  @media (any-hover: hover) {
    &:hover {
      background: ${(p) => p.color}
        radial-gradient(circle, transparent 1%, ${colors.lightGray} 1%)
        center/15000%;
      opacity: 0.7;
    }
  }

  @media (any-hover: none) {
    background: ${(p) => p.color}
      radial-gradient(circle, transparent 1%, ${colors.lightGray} 1%)
      center/15000%;
  }

  &:active {
    background-color: ${colors.lightGray};
    background-size: 100%;
    transition: background 0s;
  }
`;

import React from "react";
import LoadingPage from "../../LoadingPage";
import useSamsungLogin from "./useSamsungLogin";

const SamsungAccountCallback = () => {
  useSamsungLogin();
  return <LoadingPage />;
};

export default SamsungAccountCallback;

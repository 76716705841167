import React, { useEffect, useState } from "react";
import { Theme, ThemeProvider } from "@emotion/react";
import useTheme, { Theme as SystemTheme } from "../useTheme";
import { dark } from "./theme/dark";
import { light } from "./theme/light";
import i18n from "../../i18n";
import { isRtlLanguage } from "../../utils/LanguageUtils";
import { Theme as StyledTheme } from "./theme/Theme";

const StyledThemeProvider = (props: { children: JSX.Element }) => {
  const systemTheme = useTheme();

  const computeTheme = (): Theme => {
    const baseTheme: StyledTheme =
      systemTheme === SystemTheme.Dark ? dark : light;
    if (isRtlLanguage(i18n.language)) {
      return { ...baseTheme, direction: "rtl" };
    }
    return { ...baseTheme, direction: "ltr" };
  };

  const [theme, setTheme] = useState<Theme>(() => computeTheme());

  useEffect(() => {
    setTheme(computeTheme());
  }, [i18n.language, systemTheme]);

  const { children } = props;
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default StyledThemeProvider;

import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { SerializedStyles } from "@emotion/utils";
import { TransparentButtonRippled } from "./Ripples";

type ActionMouseEvent =
  | React.MouseEvent<HTMLButtonElement, MouseEvent>
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>;

const ContentStyled = styled(ModalContent)`
  border-radius: 26px;
  border: 1px solid ${(p) => p.theme.surfaceBackground};
  background-color: ${(p) => p.theme.surfaceBackground};
  @media (min-width: 480px) {
    width: 67%;
    min-width: 400px;
  }

  @media (min-width: 800px) {
    width: 50%;
  }

  @media (min-width: 1200px) {
    width: 37%;
  }
`;

const Header = styled(ModalHeader)`
  font-size: 17pt;
`;

const Body = styled(ModalBody)`
  color: ${(p) => p.theme.text};
`;

const Footer = styled(ModalFooter)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export type GGPopupActions = {
  actionText: string;
  onAction?: (e: ActionMouseEvent) => void;
}[];

export type Disclosure = ReturnType<typeof useDisclosure>;

const GGPopup = (props: {
  title?: string;
  className?: string;
  children?: React.ReactNode;
  hideActions?: boolean;
  actions?: GGPopupActions;
  headerStyles?: SerializedStyles;
  headerImg?: React.ReactNode;
  hideHeader?: boolean;
  maskOpacity?: number;
  disclosure: Disclosure;
  disableOverlayClose?: boolean;
  onClose?: () => void;
}) => {
  const {
    title,
    children,
    hideActions,
    headerStyles,
    headerImg,
    hideHeader,
    maskOpacity,
    disclosure,
    actions,
    disableOverlayClose,
    className,
    onClose,
  } = props;

  const [canClose, setCanClose] = useState<boolean>(false);

  useEffect(() => {
    setCanClose(false);
    const timeout = window.setTimeout(() => {
      setCanClose(true);
    }, 500);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [disclosure.isOpen]);

  const onCloseInner = () => {
    if (canClose) {
      onClose?.();
      disclosure.onClose();
    }
  };

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={onCloseInner}
      closeOnOverlayClick={!disableOverlayClose}
      closeOnEsc={!disableOverlayClose}
      isCentered
      preserveScrollBarGap
      blockScrollOnMount
      scrollBehavior="inside"
    >
      <ModalOverlay opacity={`${maskOpacity || 0.8} !important`} />
      <ContentStyled className={className}>
        {headerImg}
        {!hideHeader && <Header css={headerStyles}>{title}</Header>}
        <Body className="GGPopup-content">{children}</Body>
        {!hideActions && (
          <Footer>
            {actions?.map((action) => (
              <TransparentButtonRippled
                key={action.actionText}
                onClick={
                  action.onAction &&
                  ((e) => {
                    if (!canClose) {
                      return;
                    }
                    e.preventDefault();
                    action.onAction?.(e);
                  })
                }
              >
                {action.actionText}
              </TransparentButtonRippled>
            ))}
          </Footer>
        )}
      </ContentStyled>
    </Modal>
  );
};

export default GGPopup;

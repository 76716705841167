import type {
  SystemStyleObject,
  SystemStyleFunction,
  StyleFunctionProps,
} from "@chakra-ui/theme-tools";

// based on
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/button.ts

const baseStyle: SystemStyleObject = {
  display: "flex",
  lineHeight: "40px",
  border: "1px solid transparent",
  borderRadius: "33px",
  height: "40px",
  fontSize: "14px",
  padding: "0 25px",
  margin: "0 auto",
  textAlign: "center",
  backgroundColor: "#0a78fc",
  color: "white",
  width: "100%",
  _focus: {
    outline: "none",
    boxShadow: "none",
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed",
    boxShadow: "none",
  },
  _hover: {
    _disabled: {
      bg: "initial",
    },
  },
};

const variantGhost: SystemStyleFunction = (props: StyleFunctionProps) => {
  const { colorMode } = props;
  return {
    color: colorMode === "light" ? "#0a78fc" : "#FAFAFA",
    bg: colorMode === "light" ? "#FFFFFF" : "#252525",
    _hover: {
      bg: colorMode === "light" ? "#FFFFFF" : "#252525",
    },
    _active: {
      bg: colorMode === "light" ? "#FFFFFF" : "#252525",
    },
  };
};

const variantOutline: SystemStyleFunction = (props) => {
  const borderColor = "#0a78fc";
  return {
    border: "2px solid",
    borderColor,
    ...variantGhost(props),
  };
};

const variantSolid: SystemStyleFunction = () => {
  return {
    bg: `#0a78fc`,
    color: "white",
    _hover: {
      bg: `#0a78fc`,
      _disabled: {
        bg: "gray",
      },
    },
    _active: { bg: `#0a78fc` },
  };
};

const variantUnstyled: SystemStyleObject = {
  bg: "none",
  color: "inherit",
  display: "inline",
  lineHeight: "inherit",
  m: 0,
  p: 0,
};

const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  solid: variantSolid,
  unstyled: variantUnstyled,
};

const sizes: Record<string, SystemStyleObject> = {
  lg: {
    h: 12,
    minW: 12,
    fontSize: "lg",
    px: 6,
  },
  md: {
    h: 10,
    minW: 10,
    fontSize: "md",
    px: 4,
  },
  sm: {
    h: 8,
    minW: 8,
    fontSize: "sm",
    px: 3,
  },
  xs: {
    h: 6,
    minW: 6,
    fontSize: "xs",
    px: 2,
  },
};

const defaultProps = {
  variant: "solid",
  size: "md",
  colorScheme: "gray",
};

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};

import { useEffect, useState } from "react";
import { CookieState } from "./CookieState";

let cookieConsentState: CookieState;

const fromStorage = window.localStorage.getItem("CookieConsent");
if (!fromStorage) {
  cookieConsentState = {
    isAcceptedByUser: false,
    advertising: false,
    performance: false,
  };
} else {
  cookieConsentState = JSON.parse(fromStorage);
}

let listeners: ((state: CookieState) => void)[] = [];

const useCookieConsent = (): [CookieState, (newState: CookieState) => void] => {
  const [, setState] = useState<CookieState>(cookieConsentState);
  const dispatch = (newValue: CookieState) => {
    cookieConsentState = newValue;
    listeners.forEach((l) => l(cookieConsentState));
    window.localStorage.setItem("CookieConsent", JSON.stringify(newValue));
  };
  useEffect(() => {
    listeners.push(setState);
    return () => {
      listeners = listeners.filter((l) => l !== setState);
    };
  }, [setState]);
  return [cookieConsentState, dispatch];
};

export default useCookieConsent;
